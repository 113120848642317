import React, { Fragment, useContext, useEffect, useState } from 'react'
import { WebsiteContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import HelmetComponent from "../components/common/HelmetComponent";
import AboutList from '../components/aboutUs/AboutList';
import "./../styles/blog.css";
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';

const AboutUs = () => {
  const [courseDetails, setCourseDetails] = useState("");
  const { isMobileView ,color} = useContext(WebsiteContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchAboutUs = async () => {
    try {
      const blogCollection = collection(firestore, "about_us");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => doc.data());
      //console.log("Blog Posts:", blogList);
      setLoading(true);
      setCourseDetails(blogList);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);

  const handleCourse =(id)=>{
    navigate(`/about_us/${id}/details`)
  }
  return (
    <Fragment>
    <HelmetComponent
    title="Fluent Fable | About us"
    name="description"
    content="Discover techniques to craft compelling narratives, leverage digital tools, and engage audiences effectively in the modern digital landscape."
  />
    
    <div className={isMobileView ? "blog_container_mobile" : "blog_container"}>
      <h1 className={isMobileView ? "blog_title_mobile":"blog_title"} style={{color:color}}>About Us</h1>
      {loading && <div className="loading">
        <Loader type="spinner-cub" size={40} bgColor={color} color={color}/>
      </div>}
      {courseDetails && !loading &&
        courseDetails
          ?.sort((a, b) => a?.order - b?.order)
          ?.map((data, index) => { 
            //console.log("data0",data)
            return (
              <div className="blog_content_container" key={index}>
               <AboutList title={data?.title}
               cover_picture={data?.cover_picture}
               blogData={courseDetails}
               index={index}
               handleBlogDetails={handleCourse}
               id={data?._id}
               original_price={data?.original_price}
               offer_price = {data?.offer_price}/>
              </div>
            );
          })}
    </div>
    </Fragment>
  )
}

export default AboutUs