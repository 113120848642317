import React, { useContext, useState, useEffect, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebsiteContext } from "../../context/AppContext";
import { firestore } from "../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import "./../../styles/blog.css";
import Loader from "react-js-loader";

const AboutDetails = () => {
  const { id } = useParams();
  //console.log("Blog ID:", id);
  const [details, setDetails] = useState("");
  const { isMobileView, color } = useContext(WebsiteContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchBlogPosts = useCallback(async () => {
    try {
      const blogCollection = collection(firestore, `about_us/${id}/details`);
      const blogSnapshot = await getDocs(blogCollection); 
      const blogList = blogSnapshot.docs.map((doc) => doc.data());
      setLoading(true);
      setDetails(blogList);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchBlogPosts();
  }, [fetchBlogPosts]);

  const blog_details_title = details?.length > 0 ? details[9]?.blog_title : "";
 
  const handlePurchase =()=>{ 
    navigate(`/razorpay/${id}`)
  }
  //console.log("ididididid",id)

  const cover_picture =details&& details?.filter((data)=>data?.cover_picture);
  const picture = cover_picture[0]?.cover_picture;
  console.log("cover_picture",cover_picture)

  return (
    <div style={{ marginTop: 40 }}>
      {loading && (
        <div className="loading">
          <Loader type="spinner-cub" size={40} bgColor={color} color={color}/>
        </div>
      )}
      {!loading && (
        <Fragment>
        <div className={isMobileView ? "blog_details_content_background_mobile" : "blog_details_content_background"} onClick={handlePurchase}>Join now!</div>
        <div className="blog_details_content_title">{blog_details_title}</div>
          <div
            className={
              isMobileView
                ? "about_details_cover_picture_mobile"
                : "about_details_cover_picture"
            }
          > 
          <img src={picture} alt="cover_picture"/>
          </div>
          {details &&
            details
              ?.sort((a, b) => a.order - b.order)
              ?.map((data, index) => {
              //  console.log(data);
                return (
                  <div
                    key={index}
                    className={
                      isMobileView
                        ? "blog_details_container_mobile"
                        : "about_details_container"
                    }
                  >
                    <div
                      className="blog_details_title"
                      style={{ color: color }}
                    >
                      {data?.title}
                    </div>
                    <div
                      className="blog_details_content"
                      style={{
                        color: color,
                        marginBottom: index === details.length - 1 ? 60 : 20,
                      }}
                    >
                      {data?.content1}
                    </div>
                    <div
                    className="blog_details_content"
                    style={{
                      color: color,
                      marginBottom: index === details.length - 1 ? 60 : 20,
                    }}
                  >
                    {data?.content2}
                  </div>
                  </div>
                );
              })}
        </Fragment>
      )}
    </div>
  );
};

export default AboutDetails;
