// import { initializeApp } from "firebase/app";
// import {getFirestore} from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyD7C8syQGu327za58YmJEX91HwlmdOkT28",
//   authDomain: "fluentfable-aff58.firebaseapp.com",
//   projectId: "fluentfable-aff58",
//   storageBucket: "fluentfable-aff58.appspot.com",
//   messagingSenderId: "426890579372",
//   appId: "1:426890579372:web:11cf3fd0638f56b779be09"
// };

// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// export { firestore };
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7C8syQGu327za58YmJEX91HwlmdOkT28",
  authDomain: "fluentfable-aff58.firebaseapp.com",
  projectId: "fluentfable-aff58",
  storageBucket: "fluentfable-aff58.appspot.com",
  messagingSenderId: "426890579372",
  appId: "1:426890579372:web:11cf3fd0638f56b779be09"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };