import React, { useContext } from "react";
import { WebsiteContext } from "../../context/AppContext";

const BlogCover = ({
  title,
  date,
  cover_picture,
  time_to_read,
  language,
  blogData,
  index,
  handleBlogDetails,
  id
}) => {
  const { isMobileView, color } = useContext(WebsiteContext);
  return (
    <div onClick={()=>handleBlogDetails(id)} style={{cursor:"pointer"}}>
      <div className="blog_content_title" style={{ color: color }}>
        {title}
      </div>
      <div className="blog_content_date" style={{ color: color }}>
        ({date})
      </div>
      <div
        className={
          isMobileView ? "blog_content_image_mobile" : "blog_content_image"
        }
      >
        <img src={cover_picture} alt="cover_picture"/>
      </div>
      <div className="blog_content_time_language">
        <div className="blog_content_time">{time_to_read} mins read</div>
        <div className="blog_content_language" style={{ color: color }}>
          Language: {language}
        </div>
      </div>
      {index === blogData.length - 1 ? (
        <div style={{marginBottom:60}}></div>
      ) : (
        <div className="blog_border"></div>
      )}
    </div>
  );
};

export default BlogCover;
