import React, { useContext,useRef } from "react";
import "../../styles/common.css";
import { WebsiteContext } from "../../context/AppContext";
import "animate.css";
import {  useInView } from "framer-motion";

const ContentComponent = ({ title, content }) => {

  const { color, isMobileView } = useContext(WebsiteContext);
  const ref = useRef(null)
  const isInView = useInView(ref,{ once: true })
   
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        justifyContent: "center",
        alignItems: "center",
      }}
    ref={ref}> 
    {isInView && <h1
      className= {isMobileView ? "animate__animated animate__flipInX content_title_mobile" : "animate__animated animate__flipInX content_title"}
      style={{ color: "#0077b6" }}
    >
      {title}
    </h1> }
    {isInView &&  
      <h3 className={isMobileView ? "animate__animated animate__fadeIn animate__delay-1s content_comments_mobile": "animate__animated animate__fadeIn animate__delay-1s content_comments"} style={{ color: color }}>
        {" "}
        {content}
      </h3>
    }
    </div>
  );
};

export default ContentComponent;
