import React, { useContext } from "react";
import "./../../styles/termsOfService.css";
import { WebsiteContext } from "../../context/AppContext";

const TermsContent = ({ title, content }) => {
  const { color } = useContext(WebsiteContext);

  return (
    <div className="terms_content">
      <div className="terms_content_title" style={{ color: color }}>{title}</div>
      <div className="terms_content_points" style={{ color: color }}>{content}</div>
    </div>
  );
};

export default TermsContent;
