import React, { useContext } from "react";
import ContentComponent from "../common/ContentComponent";
import ImageComponent from "../common/ImageComponent";
import appImage1 from "../../images/app1.webp";
import { WebsiteContext } from "../../context/AppContext";

const ContentOne = () => {
  const { isMobileView } = useContext(WebsiteContext);
  return (
    <div className={isMobileView ? "content_container_mobile"  : "content_container"}>
      <ContentComponent
        title={"Comprehensive English Mastery: From Fundamentals to Fluency"}
        content={
          "Discover the full spectrum of English language learning with our meticulously crafted PDFs, designed to guide you from foundational basics to advanced proficiency"
        }
      />
      <ImageComponent image={appImage1} />
    </div>
  );
};

export default ContentOne;
