import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../styles/razorpay.css";
import emailjs from "@emailjs/browser";
import { WebsiteContext } from "../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const RazorpayComponent = () => {
  const { id } = useParams();
  //console.log("id", id);
  const [details, setDetails] = useState("");
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [course, setCourse] = useState(" ");
  const [paymentId, setPaymentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const form = useRef();
  const navigate = useNavigate();
  const { color, dark, isMobileView } = useContext(WebsiteContext);

  const fetchBlogPosts = useCallback(async () => {
    try {
      const blogCollection = collection(firestore, "about_us");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        ...doc.data(),
        _id: doc.id,
      }));

      setLoading(true);
      const currentBlog = blogList.find((data) => data._id === id);

      if (currentBlog) {
        setDetails(currentBlog || []);
        setCourse(currentBlog?.title);
        setAmount(currentBlog?.offer_price);
      } else {
        console.log("No matching document found");
        setDetails([]);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1200);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchBlogPosts();
  }, [fetchBlogPosts]);

 
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (amount === "" || isNaN(amount) || amount <= 0) {
  //     setError("Please enter a valid amount");
  //     return;
  //   }

  //   try {
  //     // Call your backend to create the order
  //     const response = await axios.post('/api/create-order', { amount });
  //     const order = response.data;

  //     // Razorpay Checkout options
  //     const options = {
  //       key: "rzp_live_MK17TqQ7xUUDjS", // Your Razorpay key ID
  //       amount: order.amount,
  //       currency: order.currency,
  //       name: "FLUENT FABLE",
  //       description: "Testing",
  //       order_id: order.id, // Use the order ID from the backend
  //       handler: function (response) {
  //         // Handle successful payment here
  //         setPaymentId(response.razorpay_payment_id);

  //         // Proceed with other actions such as sending confirmation email
  //         emailjs
  //           .sendForm(
  //             "service_reufico",
  //             "template_eza7hgm",
  //             form.current,
  //             "94QRcCXq7uSLVqVlb"
  //           )
  //           .then(
  //             (result) => {
  //               setLoading(true);
  //               setStatus(true);
  //               setName("");
  //               setEmail("");
  //               setContact("");
  //               setAlertMessage(
  //                 "Thanks for your purchase. You will receive an automated response. We will get back to you soon."
  //               );
  //               setTimeout(() => {
  //                 setLoading(false);
  //                 setAlertMessage("");
  //               }, 2000);
  //             },
  //             (error) => {
  //               setError("Failed to send email. Please try again.");
  //               console.log(error.text);
  //             }
  //           );
  //       },
  //       prefill: {
  //         name: name,
  //         email: email,
  //         contact: contact,
  //       },
  //       notes: {
  //         address: "razorpay corporate office",
  //       },
  //       theme: {
  //         color: "#330846",
  //       },
  //     };

  //     // Open Razorpay Checkout
  //     const pay = new window.Razorpay(options);
  //     pay.open();
  //   } catch (error) {
  //     console.error("Error creating order:", error);
  //     setError("Failed to create order. Please try again.");
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount === "" || name === "" || email === "" || contact === "") {
      setError("Please enter a valid details");
      return;
    }

    var options = {
      // key: "rzp_test_7bRZNR1P71fEHn", reena
      //key_secret: "jS09ybFizY2QDyr5G1VvTjiv", reena
      // key:"rzp_test_jD7cJGLn3vOzKj", // mani test key
      // key_secret:"PrcGxvu66i7VowYj7qa9DWQd", //mani test key
      key: "rzp_live_MK17TqQ7xUUDjS",
      amount: amount * 100,
      currency: "INR",
      name: "FLUENT FABLE",
      description: "Testing",
      handler: async function (response) {
        setPaymentId(response.razorpay_payment_id);
        const student = {
          name,
          amount: parseInt(amount),
          purchased_course: course,
          email,
          purchased_date: new Date(),
          payment_id: response.razorpay_payment_id,
        };

        // Save to Firestore
        try {
          await addDoc(collection(firestore, "students"), student);
        } catch (error) {
          console.error("Error saving to Firestore:", error);
          setError("Failed to save data. Please try again.");
          return;
        }
        emailjs
          .sendForm(
            "service_reufico",
            "template_eza7hgm",
            form.current,
            "94QRcCXq7uSLVqVlb"
          )
          .then(
            (result) => {
              setLoading(true);
              setStatus(true);
              setName("");
              setEmail("");
              setContact("");
              setError("");
              setAlertMessage(
                "Thanks for your purchase. You will receive an automated response. We will get back to you soon."
              );
              setTimeout(() => {
                setLoading(false);
                setAlertMessage("");
                navigate("/about_us");
              }, 2000);
            },
            (error) => {
              setError("Failed to send email. Please try again.");
              console.log(error.text);
            }
          );
      },
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },
      notes: {
        address: "razorpay corporate office",
      },
      theme: {
        color: "#330846",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();
  };

  return (
    <div className="razorpay_container">
      <h2 style={{color:color, fontWeight:500}}>Happy for your purchase! let's go ahead</h2>
      <br />
      <br />
      <form ref={form}>
        {alertMessage?.length === 0 && !loading && (
          <>
            <div
              className={
                isMobileView ? "contact__form-div_mobile" : "contact__form-div"
              }
            >
              <label
                className={
                  isMobileView
                    ? "contact__form-tag_mobile"
                    : "contact__form-tag"
                }
                style={{ color: color }}
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                value={name}
                className="contact__form-input"
                placeholder="Please Enter your name"
                onChange={(e) => setName(e.target.value)}
                required
                style={{
                  marginLeft: isMobileView ? 0 : 27,
                  color: color,
                  backgroundColor: dark ? "#0d0d0d" : "#fff",
                }}
              />
            </div>
            <div
              className={
                isMobileView ? "contact__form-div_mobile" : "contact__form-div"
              }
            >
              <label
                className={
                  isMobileView
                    ? "contact__form-tag_mobile"
                    : "contact__form-tag"
                }
                style={{ color: color }}
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                value={email}
                className="contact__form-input"
                placeholder="Please Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  marginLeft: isMobileView ? 0 : 30,
                  color: color,
                  backgroundColor: dark ? "#0d0d0d" : "#fff",
                }}
              />
            </div>
            <div
              className={
                isMobileView ? "contact__form-div_mobile" : "contact__form-div"
              }
            >
              <label
                className={
                  isMobileView
                    ? "contact__form-tag_mobile"
                    : "contact__form-tag"
                }
                style={{ color: color }}
              >
                Phone
              </label>
              <input
                type="number"
                name="contact"
                value={contact}
                className="contact__form-input"
                placeholder="Please Enter your phone number"
                onChange={(e) => setContact(e.target.value)}
                required
                style={{
                  marginLeft: isMobileView ? 0 : 23,
                  color: color,
                  backgroundColor: dark ? "#0d0d0d" : "#fff",
                }}
              />
            </div>
            <div
              className={
                isMobileView ? "contact__form-div_mobile" : "contact__form-div"
              }
            >
              <label
                className={
                  isMobileView
                    ? "contact__form-tag_mobile"
                    : "contact__form-tag"
                }
                style={{ color: color }}
              >
                Course
              </label>
              <input
                type="text"
                name="course"
                value={course}
                disabled={true}
                className="contact__form-input"
                //placeholder="Please Enter course"
                //onChange={(e) => setCourse(e.target.value)}
                required
                style={{
                  marginLeft: isMobileView ? 0 : 18,
                  color: color,
                  backgroundColor: dark ? "#0d0d0d" : "#fff",
                }}
              />
            </div>
            <div
              className={
                isMobileView ? "contact__form-div_mobile" : "contact__form-div"
              }
            >
              <label
                className={
                  isMobileView
                    ? "contact__form-tag_mobile"
                    : "contact__form-tag"
                }
                style={{ color: color }}
              >
                Amount
              </label>
              <input
                type="number"
                name="amount"
                value={amount}
                className="contact__form-input"
                disabled={true}
                //placeholder="Please Enter amount"
                //onChange={(e) => setAmount(e.target.value)}
                required
                style={{
                  marginLeft: isMobileView ? 0 : 10,
                  color: color,
                  backgroundColor: dark ? "#0d0d0d" : "#fff",
                }}
              />
            </div>
            {error && <div className="error">{error}</div>}
            <div
              className="purchase_button"
              style={{
                color: color,
                marginTop: error ? 20 : 40,
                cursor: name && email && contact ? "pointer" : "default",
                fontSize: 14,
                fontWeight:500
              }}
              onClick={handleSubmit}
            >
              Purchase
            </div>
            {loading && <div>Loading...</div>}
          </>
        )}
        {alertMessage && <div className="alert">{alertMessage}</div>}
      </form>
    </div>
  );
};

export default RazorpayComponent;
