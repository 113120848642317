import React, { Fragment, useContext } from "react";
import { FaFilePdf } from "react-icons/fa6";
import { RiSpeakFill } from "react-icons/ri";
import "../../styles/common.css";
import { WebsiteContext } from "../../context/AppContext";
import 'animate.css'
import ScrollAnimation from 'react-animate-on-scroll';

const Capsule = ({ title, image }) => {
    const {color,isMobileView} = useContext(WebsiteContext);
    
  const imageType = (image) => {
    switch (image) {
      case "spoken_english":
        return <RiSpeakFill style={{height:20, width:20,color:color}}/>;
      case "pdf":
        return <FaFilePdf style={{height:20, width:20,color:color}}/>;
      default:
        break;
    }
  };
  return (
    <Fragment>
    {
      isMobileView ?
      <div className="capsule_container_mobile">
      <div className="capsule_image" >{imageType(image)}</div>
      <h2 className="capsule_title" style={{color:color}}>{title}</h2>
    </div>
    :
    <ScrollAnimation animateIn="fadeIn" duration={3} className="capsule_container">
      <div className="capsule_image">{imageType(image)}</div>
      <h2 className="capsule_title" style={{color:color}}>{title}</h2>
    </ScrollAnimation>
    }
    </Fragment>
    
  );
};

export default Capsule;
