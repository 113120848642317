import React, { useContext } from "react";
import { WebsiteContext } from "../../context/AppContext";
import "../../styles/common.css";

const BorderLine = () => {
  const { dark } = useContext(WebsiteContext);

  return (
    <div
      className="border_top"
      style={{
        borderTop: dark ? "1px solid #212529" : "1px solid #ddd",
      }}
    ></div>
  );
};

export default BorderLine;
