import React, { Fragment } from "react"; 
import HelmetComponent from "../components/common/HelmetComponent";
import FirstSection from "../components/home/FirstSection";
import SocialMediaSection from "../components/home/SocialMediaSection";
import ReviewSection from "../components/home/ReviewSection";
import ContentOne from "../components/home/ContentOne";
import ContentTwo from "../components/home/ContentTwo";
import BorderLine from "../components/common/BorderLine";

const Home = () => { 
  return (
    <Fragment>
      <HelmetComponent
        title="Fluent Fable | Fluent Fable is a tool to boost English skills with interactive pdfs"
        name="description"
        content="Fluent Fable is a tool to boost English skills with interactive tools and downloadable PDF resources for effective language learning and practice."
      />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1208,
        }}
      >
        <FirstSection />
      </div>
      <BorderLine />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1208,
        }}
      >
        <ContentOne />
      </div>
      <BorderLine />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1208,
        }}
      >
        <ContentTwo />
      </div>
      <BorderLine />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1208,
        }}
      >
        <SocialMediaSection />
      </div>
      <BorderLine />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1208,
        }}
      >
        <ReviewSection />
      </div>
    </Fragment>
  );
};

export default Home;
