import React, { Fragment,Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";
import Blog from'./Blog';
import BlogDetails from "../components/blog/BlogDetails";
import RazorpayComponent from "./RazorpayComponent";
import AboutUs from "./AboutUs";
import AboutDetails from "../components/aboutUs/AboutDetails";

const Main = () => {
  return (
    <Fragment>
    <Suspense fallback={<div style={{height:"100vh"}}> </div>}>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/terms-of-service" element={<TermsOfService/>}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
          <Route path="/contact" element={<ContactUs/>}></Route>
          <Route path="/blogs" element={<Blog/>}></Route>
          <Route path="/blogs/:id/blog_details" element={<BlogDetails/>}></Route>
          <Route path="/about_us/:id/details" element={<AboutDetails/>}></Route>
          <Route path="/razorpay/:id" element={<RazorpayComponent/>}></Route>
          <Route path="/about_us" element={<AboutUs/>}></Route>
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
        </Suspense>
    </Fragment>
  );
};

export default Main;
