import React, { Fragment, useContext, useEffect, useState } from "react";
import { firestore } from "./../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import "./../styles/blog.css";
import { WebsiteContext } from "../context/AppContext";
import Loader from "react-js-loader";
import BlogCover from "../components/blog/BlogCover";
import HelmetComponent from "../components/common/HelmetComponent";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const [blogData, setBlogData] = useState("");
  const { isMobileView ,color} = useContext(WebsiteContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchBlogPosts = async () => {
    try {
      const blogCollection = collection(firestore, "blogs");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => doc.data());
      //console.log("Blog Posts:", blogList);
      setLoading(true);
      setBlogData(blogList);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const timestampToDate = (timestamp) => {
    return new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000);
  };

  const formatDate = (date) => {
    //moment(date).format("MMMM D, YYYY [at] h:mm:ss A");
    return moment(date).format("MMMM D, YYYY");
  };

  // console.log("blogData", blogData);

  const handleBlogDetails =(id)=>{
    navigate(`/blogs/${id}/blog_details`)
  }
  return (
    <Fragment>
    <HelmetComponent
    title="Fluent Fable | Blog"
    name="description"
    content="Discover techniques to craft compelling narratives, leverage digital tools, and engage audiences effectively in the modern digital landscape."
  />
    
    <div className={isMobileView ? "blog_container_mobile" : "blog_container"}>
      <h1 className={isMobileView ? "blog_title_mobile":"blog_title"} style={{color:color}}>Blogs</h1>
      {loading && <div className="loading">
        <Loader type="spinner-cub" size={40} bgColor={color} color={color}/>
      </div>}
      {blogData && !loading &&
        blogData
          ?.sort((a, b) => a?.order - b?.order)
          ?.map((data, index) => {
            const date = timestampToDate(data.created_at);
            const formattedDate = formatDate(date);
            return (
              <div className="blog_content_container" key={index}>
               <BlogCover title={data?.title}
               date={formattedDate}
               cover_picture={data?.cover_picture}
               time_to_read={data?.time_to_read}
               language={data?.language}
               blogData={blogData}
               index={index}
               handleBlogDetails={handleBlogDetails}
               id={data?._id}/>
              </div>
            );
          })}
    </div>
    </Fragment>
  );
};

export default Blog;
