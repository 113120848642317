import React, { Fragment, useContext, useRef, useState } from "react";
import HelmetComponent from "../components/common/HelmetComponent";
import emailjs from "@emailjs/browser";
import contactImage from "../images/contact1.png";
import "../styles/contact.css";
import { WebsiteContext } from "../context/AppContext";
import Loader from "react-js-loader";
import "animate.css";

const ContactUs = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const form = useRef();
  const { color, dark, handleRoute, isMobileView } = useContext(WebsiteContext);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_reufico",
        "template_auy4oho",
        form.current,
        "94QRcCXq7uSLVqVlb"
      )
      .then(
        (result) => {
          //console.log(result.text);
          setLoading(true);
          setStatus(true);
          setUsername("");
          setEmail("");
          setMessage("");
          setAlert(
            "Thanks for Your Message, You will receive the automated response. We will get back to You soon."
          );
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleRouteContact = () => {
    handleRoute("Contact");
    setStatus(false);
  };
  return (
    <Fragment>
      <HelmetComponent
        title="Contact Us | We would love to hear from you ❤️"
        name="description"
        content="Reach out the team and get your query sort out"
      />

      <div
        className={
          isMobileView
            ? "contact_container_mobile"
            : "animate__animated animate__fadeIn animate__delay-0.2s  contact_container"
        }
      >
        {!status && (
          <div
            className={
              isMobileView
                ? "contact_sub_container_mobile"
                : "contact_sub_container"
            }
          >
          {isMobileView && (
            <div className="form_right_mobile">
              <img
                src={contactImage}
                alt="contact"
                className="contact_image_mobile"
              />
            </div>
          )}
            <div className="form_left">
              <form ref={form}>
                <div
                  className={
                    isMobileView
                      ? "contact__form-div_mobile"
                      : "contact__form-div"
                  }
                >
                  <label
                    className={
                      isMobileView
                        ? "contact__form-tag_mobile"
                        : "contact__form-tag"
                    }
                    style={{ color: color }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={username}
                    className="contact__form-input"
                    placeholder="Please Enter your name"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    style={{
                      marginLeft: isMobileView ? 0 : 27,
                      color: color,
                      backgroundColor: dark ? "#0d0d0d" : "#fff",
                    }}
                  />
                </div>
                <div
                  className={
                    isMobileView
                      ? "contact__form-div_mobile"
                      : "contact__form-div"
                  }
                >
                  <label
                    className={
                      isMobileView
                        ? "contact__form-tag_mobile"
                        : "contact__form-tag"
                    }
                    style={{ color: color }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="contact__form-input"
                    placeholder="Please Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      marginLeft: isMobileView ? 0 : 30,
                      color: color,
                      backgroundColor: dark ? "#0d0d0d" : "#fff",
                    }}
                  />
                </div>
                <div
                  className={
                    isMobileView
                      ? "contact__form-div_mobile contact__form-area"
                      : "contact__form-div contact__form-area"
                  }
                >
                  <label
                    className={
                      isMobileView
                        ? "contact__form-tag_mobile"
                        : "contact__form-tag"
                    }
                    style={{ color: color }}
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    value={message}
                    className="contact__form-input"
                    placeholder="Please Enter your message"
                    onChange={(e) => setMessage(e.target.value)}
                    style={{
                      color: color,
                      backgroundColor: dark ? "#0d0d0d" : "#fff",
                      marginLeft: isMobileView ? 0 : 4
                    }}
                  />
                </div>

                {error && <div className="error">{error}</div>}
                <div
                  className="button"
                  style={{
                    color: color,
                    marginTop: error ? 20 : 40,
                    cursor:
                      username && email && message ? "pointer" : "default",
                  }}
                  onClick={(e) => {
                    if (username && email && message) {
                      sendEmail(e);
                    } else {
                      setError("Please fill in all required fields.");
                    }
                  }}
                >
                  Send message
                </div>
              </form>
            </div>
            {!isMobileView && (
              <div className="form_right">
                <img
                  src={contactImage}
                  alt="contact"
                  className="contact_image"
                />
              </div>
            )}
          </div>
        )}

        {status && !loading && (
          <div className="alert_text_container">
            <div className="alert_text" style={{ color: color }}>
              {alert}
            </div>
            <div
              onClick={handleRouteContact}
              className="button"
              style={{ color: color, cursor: "pointer" }}
            >
              Go Back
            </div>
          </div>
        )}
        {loading && (
          <div className="loading">
            <Loader type="spinner-cub" size={40} bgColor={color} color={color}/>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ContactUs;
