import React, { Fragment, useContext } from "react";
import HelmetComponent from "../components/common/HelmetComponent";
import TermsContent from "../components/common/TermsContent";
import "../styles/termsOfService.css";
import { WebsiteContext } from "../context/AppContext";

const TermsOfService = () => {
  const termsContent = [
    {
      title: "Acceptance of Terms:",
      content:
        "By accessing or using the Fluent Fable App, you agree to be bound by these Terms & Conditions, our Privacy Policy, and all applicable laws and regulations.",
    },
    {
      title: "Description of Service:",
      content:
        "The Fluent Fable App provides users with access to educational content and tools to improve English language skills. This includes but is not limited to lessons, exercises, quizzes, and communication features.",
    },
    {
      title: "User Conduct:",
      content:
        "Users agree to use the Fluent Fable App for lawful purposes only and to not engage in any conduct that could harm the app, its users, or third parties. This includes but is not limited to: Posting or transmitting any content that is unlawful, threatening, abusive, defamatory, or otherwise objectionable.Impersonating any person or entity, or falsely stating orotherwisemisrepresenting your affiliation with a person or entity.Interfering with or disrupting the operation of the app or servers or networks connected to the app.",
    },
    {
      title: "Intellectual Property:",
      content:
        "All content and materials provided through the Fluent Fable App, including but not limited to text, graphics, logos, images, and software, are the property of the app or its licensors and are protected by intellectual property laws.",
    },
    {
      title: "Acceptance of Terms:",
      content:
        "By accessing or using the Fluent Fable App, you agree to be bound by these Terms & Conditions, our Privacy Policy, and all applicable laws and regulations.",
    },
    {
      title: "User Content:",
      content:
        "By submitting any content to the Fluent Fable App, including but not limited to comments, feedback, or suggestions, you grant the app a worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any form.",
    },
    {
      title: "Privacy:",
      content:
        "Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using the Fluent Fable App, you consent to the terms of our Privacy Policy.",
    },
    {
      title: "Modifications to Service:",
      content:
        "The Fluent Fable App reserves the right to modify, suspend, or discontinue all or any part of the service at any time, with or without notice.",
    },
    {
      title: "Termination:",
      content:
        "The Fluent Fable App reserves the right to terminate or suspend your access to the service at any time, for any reason, without prior notice.",
    },
    {
      title: "Disclaimer of Warranties:",
      content: `The Fluent Fable App is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. The app makes no warranties or representations about the accuracy or completeness of the content provided.`,
    },
    {
      title: "Limitation of Liability:",
      content: `In no event shall the Fluent Fable App or its affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, arising out of or in connection with your use of the service.`,
    },
    {
      title: "Governing Law:",
      content: `These Terms & Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.`,
    },
    {
      title: "Contact Us:",
      content: `If you have any questions or concerns about these Terms & Conditions, please contact us at vlearnenglish2023@gmail.com`,
    },
  ];
  const { color, isMobileView } = useContext(WebsiteContext);
  return (
    <Fragment>
      <HelmetComponent
        title="Terms of Service | Fluent Fable"
        name="description"
        content="Review our terms of service to understand how we use and protect your information."
      />
      <div
        className={isMobileView ? "terms_title_mobile" : "terms_title"}
        style={{ color: color }}
      >
        Terms & Conditions
      </div>
      <div
        className={isMobileView ? "terms_subtitle_mobile" : "terms_subtitle"}
        style={{ color: color }}
      >
        We Are Fluent Fable : Welcome to the Fluent Fable! Before using our
        services, please read these Terms & Conditions carefully
      </div>
      <div
        className={isMobileView ? "terms_container_mobile" : "terms_container"}
      >
        {termsContent?.map((data, index) => {
          return (
            <div key={index}>
              <TermsContent title={data?.title} content={data?.content} />
            </div>
          );
        })}

        <div className="terms_info" style={{ color: color }}>
          By using the Fluent Fable App, you acknowledge that you have read,
          understood, and agree to be bound by these Terms & Conditions. If you
          do not agree to these terms, please do not use the app.
        </div>
      </div>
    </Fragment>
  );
};

export default TermsOfService;
