import "./App.css";
import Main from "./pages/Main";
import { useContext } from "react";
import NavBarComponent from "./components/navBar/NavBarComponent";
import { WebsiteContext } from "./context/AppContext";
import FooterComponent from "./components/footer/FooterComponent";

function App() {
  const {dark} = useContext(WebsiteContext);
  return (
    <div className={dark ? "App_dark": "App_light"}>
      <NavBarComponent />
      <Main />
      <FooterComponent />
    </div>
  );
}

export default App;
