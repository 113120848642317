import React, { useRef } from 'react';
import '../../styles/common.css';
import { useInView } from 'framer-motion';


const ImageComponent = ({image}) => {
  const ref = useRef(null)
  const isInView = useInView(ref,{ once: true })
  return (
    <div className='content_image' ref={ref}>
    {
      isInView && <img src={image} className='animate__animated animate__fadeIn content_title' alt='contentImage'/>
    }
     
    </div>
  )
}

export default ImageComponent