import React, { useContext } from 'react';
import '../../styles/common.css';
import { WebsiteContext } from '../../context/AppContext';
import Avatar from 'react-avatar';

const ReviewCard = ({name,comments,image,backgroundColor}) => {

  const { color, isMobileView, dark } = useContext(WebsiteContext);

  return (
    <div className={isMobileView ? "review_box_mobile" :"review_box"} style={{ backgroundColor : dark ? "" : backgroundColor}}>
      <div className="user_image"> <Avatar src={image} size='60' round={true} alt="avatar"/> </div>
      <div className="reviewer_name" style={{ color: color }}>
        {name}
      </div>
      <div className="review_comments" style={{ color: color }}>
           {comments}
      </div>
    </div>
  )
}

export default ReviewCard