import React, { useContext, useRef } from "react";
import CountUp from "react-countup";
import { TfiYoutube } from "react-icons/tfi";
import { RiInstagramFill } from "react-icons/ri";
import "../../styles/common.css";
import { WebsiteContext } from "../../context/AppContext";
import { useInView } from "framer-motion";

const SocialMediaCard = ({ title, count, image, subcontent }) => {
  
  const imageType = (image) => {
    switch (image) {
      case "youtube":
        return <TfiYoutube style={{ height: 60, width: 60, color: color }} />; 
      case "instagram":
        return (
          <RiInstagramFill style={{ height: 60, width: 60, color: color }} />
        ); 
      default:
        break;
    }
  };
  const { color } = useContext(WebsiteContext);
  const ref = useRef(null)
  const isInView = useInView(ref,{ once: true })
  // const { ref, inView } = useInView({
  //   triggerOnce: true, // Ensures the CountUp animation runs only once
  //   threshold: 0.1, // Adjust this value based on when you want the count-up to start
  // });
  return (
    <div className="youtube_container" ref={ref}>
      <div className="social_image">{imageType(image)}</div>
      <h1 className="social_title" style={{ color: color }}>
        {title}
      </h1>
      <h2 className="social_count">
        {isInView && <CountUp start={0} end={count} duration={5} />}

        <span className="subscribes_plus" style={{ color: "#0077b6" }}>
          +
        </span>
        <span className="subscribes" style={{ color: color }}>
          {"   "}
          {`${subcontent}`}
        </span>
      </h2>
    </div>
  );
};

export default SocialMediaCard;
