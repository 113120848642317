import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const WebsiteContext = createContext();

export const AppProvider = ({ children }) => {
  const [dark, setDark] = useState(false);
  const color = dark ? "rgb(250, 249, 246, 0.8)" : "#0d0d0d";
  const handleThemeMode = () => {
    setDark(!dark);
  };

  const navigate = useNavigate();
  const handleRoute = (type) => {
    switch (type) {
      case "Home":
        navigate("/");
        break;
      case "Contact":
        navigate("/contact");
        break;
        case "About":
          navigate("/about_us");
          break;
      case "Blog":
        navigate("/blogs");
        break;
      case "Privacy_policy":
        navigate("/privacy-policy");
        break;
      case "Terms_of_service":
        navigate("/terms-of-service");
        break;
      case "GettheApp":
        window.open(
          "https://play.google.com/store/apps/details?id=com.fluentfable&hl=en"
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }, 0);
  };

  const [isMobileView, setIsMobileView] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkViewport();
    window.addEventListener("resize", checkViewport);
    return () => {
      window.removeEventListener("resize", checkViewport);
    };
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 200);

  const checkViewport = () => {
    const isMobileView = window.matchMedia("(max-width: 767px)").matches;
    setIsMobileView(isMobileView);
  };

  const contextValue = {
    handleThemeMode,
    dark,
    color,
    handleRoute,
    isMobileView,
    loading,
  };

  return (
    <WebsiteContext.Provider value={contextValue}>
      {children}
    </WebsiteContext.Provider>
  );
};
