import React, { Fragment, useContext } from "react";
import ImageComponent from "../common/ImageComponent";
import ContentComponent from "../common/ContentComponent";
import appImage1 from "../../images/app3.webp";
import { WebsiteContext } from "../../context/AppContext";

const ContentTwo = () => {
  const { isMobileView } = useContext(WebsiteContext);
  return (
    <Fragment>
      {" "}
      {isMobileView ? (
        <div className="content_container_mobile">
          <ContentComponent
            title={
              "Mastering Conversational English: Confident Speaking with New Voices"
            }
            content={
              "Embrace the challenge of speaking with new people and refine your communication abilities through engaging dialogues and thoughtful tips."
            }
          />
          <ImageComponent image={appImage1} />
        </div>
      ) : (
        <div className="content_container">
          <ImageComponent image={appImage1} />
          <ContentComponent
            title={
              "Mastering Conversational English: Confident Speaking with New Voices"
            }
            content={
              "Embrace the challenge of speaking with new people and refine your communication abilities through engaging dialogues and thoughtful tips."
            }
          />
        </div>
      )}
    </Fragment>
  );
};

export default ContentTwo;
