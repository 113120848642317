import React, { useContext } from "react";
import "../../styles/homepage.css";
import Capsule from "../common/Capsule";
import { WebsiteContext } from "../../context/AppContext";
import appImage from "../../images/mainImage.webp";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import lottie1 from "../../lottie/lottie1.json";
import lottie2 from "../../lottie/lottie2.json";
import "animate.css";

const FirstSection = () => {
  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 100,
    duration: 3,
  };

  const { color, isMobileView, dark } = useContext(WebsiteContext);
  //console.log("isMobileView", isMobileView);
  return (
    <div className="first_section_container">
      <div className="heading" style={{ color: color }}>
        {dark ? (
          <Lottie animationData={lottie2} loop={false} rel="preload"/>
        ) : (
          <Lottie animationData={lottie1} loop={false} rel="preload"/>
        )}
      </div>
      <h1
        className={
          isMobileView
            ? "animate__animated animate__flipInX sub_heading_mobile"
            : "animate__animated animate__flipInX sub_heading"
        }
       // style={{ color: color }}
      >
        "Fluency is not a destination but a journey, and every practice brings
        you closer to your goal".
      </h1>
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={spring}
        className="app_new_image"
      >
        <img src={appImage} alt="appImage"  fetchPriority="high"/>
      </motion.div>
      {isMobileView ? (
        <div className="content_mobile">
          <Capsule title={"Spoken English"} image={"spoken_english"} />
          <Capsule title={"Basic to Advance English"} image={"pdf"} />
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={spring}
          className="content"
        >
          <Capsule title={"Spoken English"} image={"spoken_english"} />
          <Capsule title={"Basic to Advance English"} image={"pdf"} />
        </motion.div>
      )}
    </div>
  );
};

export default FirstSection;
